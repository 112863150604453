.org-card {
  background-color: white;
  color: #242424;
  border-radius: 0.5em;
  transition: all 0.3s ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &__image {
    width: 200px;
    height: fit-content;
    object-fit: contain;
    border-top-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
  }

  h3 {
    font-size: 1em;
    text-align: center;
    padding: 1em 0;
    word-break: break-word;
  }
}
