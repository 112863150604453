.field-with-description {
  padding: 1.5em;
  background-color: var(--primary-gray-color);
  border-radius: 0.5em;
  margin: 1em 0;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &--chosen {
    border-color: var(--primary-blue-color);
    background-color: #d1eff759;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      padding: 0.5em;
      border-radius: 50%;
      border: 1px solid gray;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &__title {
    color: black;
    font-weight: 600;
  }

  &__description {
    margin-top: 1em;
    color: #6c6c6c;
  }
}
