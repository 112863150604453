.option-tab {
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  background-color: #f4f4f4;
  border: 1px solid rgba(36, 36, 36, 0.16);
  justify-content: space-between;
  margin-top: 1em;

  &__button {
    border: 0px;
    width: 50%;
    padding: 1em;
    color: #9a9a9a;
    font-weight: 600;
    cursor: pointer;

    &--active {
      background-color: #e9e9e9;
      color: #242424;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
