.label-card {
  background-color: #fafafa;
  border: 1px solid rgba(36, 36, 36, 0.16);
  border-radius: 0.5em;
  padding: 0.7em 1em;
  margin: 1em 0;

  &__label {
    font-size: 0.75em;
    margin-bottom: 0.5em;
    color: #9a9a9a;
  }

  &__description {
    font-size: 0.9em;
    font-weight: 600;
  }
}
