.add-new-member {
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }

  &__fields {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    align-items: center;
    gap: 1em;
    position: relative;
  }

  &__input {
    margin: 0.7em 0;
    background-color: var(--primary-color);
    border: 0px;
    border-radius: 0.5em;
    border: 1px solid rgba(36, 36, 36, 0.16);
    padding: 1em 1.5em;
    font-size: 0.9em;
    width: 70%;

    @media (max-width: 769px) {
      padding-right: 3em;
    }

    display: flex;
    &:focus-visible {
      outline: inherit;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &::placeholder {
      color: rgb(148, 148, 148);
      font-weight: 100;
      font-family: "Montserrat", sans-serif;
    }
    &--error {
      border: 1px solid #fd2b2b;
    }
  }

  .field__select {
    margin: 0.7em 0;
    border: 0px;
    border-radius: 0.5em;
    color: white;
    display: flex;
    width: 100%;
  }
}
