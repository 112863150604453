.chip {
  padding: 0.75em;
  color: var(--text-gray-color);
  border-radius: 100px;
  font-weight: 600;

  p {
    font-size: 0.875em;
  }
}
