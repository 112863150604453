.role-item {
  margin: 1em 0;
  padding: 1em;
  background-color: var(--primary-gray-color);
  border-radius: 0.5em;

  input {
    width: 50%;
    padding: 0.5em;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 0.5em;

    span {
      font-size: 0.7em;
      opacity: 0.7;
    }
  }

  &__actions {
    p {
      font-size: 0.85em;
      margin-bottom: 1em;
    }
  }

  &__basic-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
}
