.create-role-config {
  &__add-button {
    padding: 0.75em 1em;
    border-radius: 1em;
    border: 1px solid #242424;
    background-color: transparent;
    color: #242424;
    display: flex;
    align-items: center;
    gap: 0.5em;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &__actions {
    display: flex;
    align-items: end;
    flex-direction: column;
  }
}
