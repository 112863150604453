.treasury-details {
  margin: 0 4em;
  padding-bottom: 2em;

  &__org-name {
    span {
      font-weight: 600;
    }
    margin: 1em 0;
  }

  &__org {
    display: flex;
    gap: 1em;
    margin: 1em 0;
  }

  &__org-img {
    width: 50px;
    height: fit-content;
    border-radius: 50%;
    object-fit: cover;
  }
  &__rules {
    margin: 1em 0;
    font-size: 0.875em;
  }

  &__governance-basic {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &__governance {
    display: flex;
    gap: 1em;
    span {
      font-weight: 600;
    }
  }

  &__treasury-basic {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0.5em 0;

      span {
        font-weight: 600;
      }
    }
  }

  &__proposals {
    margin: 1em 0;
  }

  &__button-svg {
    filter: invert(1);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1em;
  }
}
