.treasuries {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5em;
    padding: 1.5em;
    background-color: white;
    border-radius: 0.5em;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__item-title {
    font-weight: 600;

    span {
      color: var(--text-gray-color);
      font-size: 0.875em;
    }
  }

  &__item-balance {
    font-size: 0.875em;
  }
  &__item-address {
    font-size: 0.875em;
  }
}
