.org-proposals {
  &__main-treasury {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 0.5em;
    padding: 1em 2em;
    margin-bottom: 1em;
  }

  &__rules {
    display: flex;
    flex-direction: column;
    gap: 1em;

    button {
      align-self: center;
    }
  }

  &__title {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  &__info {
    display: flex;
    gap: 1em;
    font-size: 0.875em;
  }

  &__info-label {
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  &__treasury-address {
    font-size: 0.875em;
    font-weight: 600;
  }
}
