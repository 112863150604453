.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  border: 1px solid rgba(36, 36, 36, 0.16);
  margin: 0.7em 0;
  border-radius: 0.5em;
  font-size: 0.9em;
  color: #6c6c6c;
}
