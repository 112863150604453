.select-button {
  position: relative;
  button {
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 1em;
    padding: 0.5em 1em;
    border: 1px solid black;
    background-color: transparent;
    height: 32px;
    justify-content: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__option-list {
    background-color: rgb(255, 255, 255);
    width: max-content;
    right: 0;
    margin-top: 0.5em;
    border-radius: 0.5em;
    z-index: 10;
    position: absolute;
  }

  &__option-item {
    padding: 0.75em 1em;
    font-size: 0.875em;

    &:hover {
      background-color: rgba(203, 198, 198, 0.067);
      cursor: pointer;
    }
  }

  &__backdrop {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
