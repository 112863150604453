.form-field {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.4;
    }
  }

  &__input {
    margin: 0.7em 0;
    background-color: var(--primary-color);
    border: 0px;
    border-radius: 0.5em;
    border: 1px solid rgba(36, 36, 36, 0.16);
    padding: 1em 1.5em;
    font-size: 0.9em;

    @media (max-width: 769px) {
      padding-right: 3em;
    }

    display: flex;
    width: 100%;
    &:focus-visible {
      outline: inherit;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &::placeholder {
      color: rgb(148, 148, 148);
      font-weight: 100;
      font-family: "Montserrat", sans-serif;
    }
    &--error {
      border: 1px solid #fd2b2b;
    }
  }

  &__error {
    color: rgb(192, 12, 12);
    font-size: 13px;
    margin-left: 1em;
    margin-bottom: 0.5em;
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em 1em;
    border: 1px solid rgba(36, 36, 36, 0.16);
    margin: 0.7em 0;
    border-radius: 0.5em;
    font-size: 0.9em;
    color: #6c6c6c;
  }
}
