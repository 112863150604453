.create-org {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0;

  &__form {
    width: 50%;
    background-color: white;
    padding: 2em;
    border-radius: 0.5em;
  }

  &__form-header {
    display: flex;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--primary-blue-color);
    margin-bottom: 1em;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #242424;
      font-size: 1em;
      font-weight: 700;
    }
  }

  &__form-header-step {
    color: #9a9a9a;
    font-size: 0.875em;
  }

  &__buttons {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px solid #9a9a9a74;
    display: flex;
    justify-content: space-between;
  }
  &__button {
    padding: 0.75em 1em;
    border-radius: 1em;
    border: 1px solid transparent;
    background-color: #242424;
    color: white;

    &--cancel {
      background-color: transparent;
      color: #242424;
      border: 1px solid #242424;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7em;
    }
  }

  &__custom-error {
    color: rgb(192, 12, 12);
    font-size: 13px;
    margin-left: 1em;
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
}
