.setup-gov {
  margin: 1em 0;
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    margin: 1em 0;
  }

  p {
    font-weight: 600;
    font-size: 0.875em;
    margin-bottom: 1em;
  }
}
