.preview-org {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;

    img {
      width: 100px;
      height: fit-content;
      border-radius: 50%;
    }

    p {
      font-size: 0.85em;
    }
  }
}
